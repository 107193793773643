import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/Seo"
import PortableTextBlock from "../components/PortableTextBlock"

const PageTemplate = ({ data }) => {
  const page = data && data.page

  return (
    <>
      <Seo
        metaTitle={page.seo?.metaTitle || page.title}
        shareTitle={page.seo?.shareTitle || page.title}
        metaDesc={page.seo?.metaDesc}
        shareDesc={page.seo?.shareDesc}
        shareGraphic={page.seo?.shareGraphic?.asset.url}
      />
      <section className="wrapper py-2">
        <div className="max-w-3xl">
          <h1 className="h6">{page.title}</h1>
          <PortableTextBlock value={page._rawContent} />
        </div>
      </section>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query legalPageQuery($_id: String!) {
    page: sanityLegalPage(_id: { eq: $_id }) {
      id
      title
      _rawContent(resolveReferences: { maxDepth: 99 })
      seo {
        ...seoFields
      }
    }
  }
`
